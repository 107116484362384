import { useRecoilValue } from 'recoil'
import useSWR from 'swr'
import { touristTokenState } from '../store/tourist'
import { userState } from '../store/user'

const useGet = <T>(
  key: Parameters<typeof useSWR>[0],
  { isPublic } = { isPublic: false }
) => {
  const touristToken = useRecoilValue(touristTokenState)
  const user = useRecoilValue(userState)
  return useSWR<T>((isPublic ? true : touristToken || user) && key)
}

export { useGet }
