/* eslint-disable jsx-a11y/alt-text */
import { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSearchParams } from 'react-router-dom'
import { Api } from '../../api'
import { Button } from '../../components/Button'
import { Loading } from '../../components/Loading'
import { Tc } from '../../components/Tc'
import { useGet } from '../../hooks/get'
import s from './TermsConditions.module.css'

const TermsConditions: FC = () => {
  const [searchParams] = useSearchParams()

  const { data } = useGet<Dto.Tc | { message: string }>(
    ['website/terms_conditions', { token: searchParams.get('token') }],
    { isPublic: true }
  )

  const handleAcceptClick = async () => {
    await Api.post('website/terms_conditions/agreement', {
      token: searchParams.get('token'),
    })
    window.location.reload()
  }

  if (!data) {
    return <Loading />
  }

  return (
    <>
      <Helmet>
        <title>Accept Terms & Conditions</title>
      </Helmet>
      <div className={s.root}>
        {'message' in data ? (
          data.message
        ) : (
          <>
            <Tc data={data} />
            <div className={s.actions}>
              <Button block onClick={handleAcceptClick}>
                Accept
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export { TermsConditions }
